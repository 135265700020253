import { template as template_3f14394dfad148d8958efeb0a0c6fc62 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_3f14394dfad148d8958efeb0a0c6fc62(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
