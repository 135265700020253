import { template as template_6f357b2b62e543a6a3532a28d73e0d8d } from "@ember/template-compiler";
const FKText = template_6f357b2b62e543a6a3532a28d73e0d8d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
