import { template as template_c7c26a6fad02404ab778369cbfdaf54d } from "@ember/template-compiler";
const FKControlMenuContainer = template_c7c26a6fad02404ab778369cbfdaf54d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
