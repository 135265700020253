import { template as template_fed4c84ece814b97b4c29c7a0d5b83fa } from "@ember/template-compiler";
const SidebarSectionMessage = template_fed4c84ece814b97b4c29c7a0d5b83fa(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
