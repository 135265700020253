import { template as template_7d108095f2bf4047a26d0bbd208bd4ec } from "@ember/template-compiler";
const FKLabel = template_7d108095f2bf4047a26d0bbd208bd4ec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
