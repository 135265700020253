import { template as template_d675ddb317844604b6e1fa9a7d5b0185 } from "@ember/template-compiler";
const WelcomeHeader = template_d675ddb317844604b6e1fa9a7d5b0185(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
